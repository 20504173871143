<template>
    <div id="ai_chatbot" class="title">
        Meet the ChatBot App:<br />Your Ultimate AI Assistant
    </div>
    <div class="info">
        <img class="img" src="../assets/images/our-product.webp" />
        <div class="text-content">
            <div class="sub-title">Quick Answers, Endless Possibilities</div>
            <div class="text">
                Discover the power of cutting-edge AI technologies, where every
                interaction is an opportunity to learn, grow, and innovate.
            </div>
            <br />
            <br />
            <div class="text">
                Our intelligent chatbot app is designed to give you quick
                answers, unlock innovative ideas, and empower you with new
                knowledge. Just type in your request or send it with your voice
                — and get the answer in a matter of seconds.
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OurProduct',
}
</script>

<style scoped lang="scss">
.title {
    margin-bottom: 40px;

    font-size: 30px;
    font-weight: 600;
    line-height: 50px;
    text-align: center;
}

.info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.img {
    width: 100%;
    max-width: 350px;
}

.sub-title {
    margin: 30px 0 15px;

    font-size: 26px;
    font-weight: 600;
    line-height: 36px;
}

.text {
    font-size: 16px;
    line-height: 26px;
}

@media screen and (min-width: 1200px) {
    .title {
        margin: 120px 0 50px;

        font-size: 50px;
        line-height: 70px;

        text-align: center;
    }

    .info {
        flex-direction: row;

        gap: 150px;
        max-width: 1300px;
    }

    .img {
        max-width: 510px;
    }

    .sub-title {
        margin: 0 0 15px;

        font-size: 38px;
        font-weight: 600;
        line-height: 53px;
    }

    .text {
        font-size: 18px;
        line-height: 29px;
    }
}
</style>
