<template>
    <svg
        width="37"
        height="37"
        viewBox="0 0 37 37"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect width="37" height="37" rx="18.5" fill="#0E1011" />
        <path
            d="M18.5021 19.3281C17.522 19.3281 16.5639 19.0375 15.749 18.493C14.9341 17.9485 14.2989 17.1746 13.9238 16.2691C13.5488 15.3636 13.4506 14.3672 13.6419 13.4059C13.8331 12.4447 14.305 11.5617 14.9981 10.8687C15.6911 10.1756 16.5741 9.70366 17.5353 9.51246C18.4966 9.32125 19.493 9.41938 20.3985 9.79445C21.3039 10.1695 22.0779 10.8047 22.6224 11.6196C23.1669 12.4345 23.4575 13.3926 23.4575 14.3727C23.4575 15.687 22.9354 16.9474 22.0061 17.8767C21.0768 18.8061 19.8164 19.3281 18.5021 19.3281ZM18.5021 11.0691C17.8487 11.0691 17.21 11.2628 16.6667 11.6258C16.1234 11.9888 15.7 12.5048 15.4499 13.1084C15.1999 13.7121 15.1345 14.3764 15.2619 15.0172C15.3894 15.658 15.704 16.2467 16.1661 16.7087C16.6281 17.1707 17.2167 17.4854 17.8576 17.6128C18.4984 17.7403 19.1627 17.6749 19.7663 17.4249C20.37 17.1748 20.8859 16.7514 21.249 16.2081C21.612 15.6648 21.8057 15.0261 21.8057 14.3727C21.8057 13.4965 21.4577 12.6562 20.8381 12.0367C20.2186 11.4171 19.3783 11.0691 18.5021 11.0691Z"
            fill="white"
        />
        <path
            d="M18.4974 30.8925C16.6659 30.8913 14.8574 30.484 13.2022 29.6999C11.547 28.9159 10.0862 27.7746 8.92508 26.3582L8.49561 25.8297L8.92508 25.3093C10.0872 23.8948 11.5484 22.7554 13.2036 21.9732C14.8587 21.1909 16.6667 20.7852 18.4974 20.7852C20.3281 20.7852 22.136 21.1909 23.7911 21.9732C25.4463 22.7554 26.9075 23.8948 28.0696 25.3093L28.4991 25.8297L28.0696 26.3582C26.9085 27.7746 25.4477 28.9159 23.7925 29.6999C22.1373 30.484 20.3289 30.8913 18.4974 30.8925ZM10.6595 25.8379C11.664 26.9133 12.8788 27.7706 14.2285 28.3568C15.5783 28.943 17.0341 29.2455 18.5056 29.2455C19.9771 29.2455 21.433 28.943 22.7827 28.3568C24.1324 27.7706 25.3473 26.9133 26.3518 25.8379C25.3473 24.7626 24.1324 23.9052 22.7827 23.319C21.433 22.7328 19.9771 22.4303 18.5056 22.4303C17.0341 22.4303 15.5783 22.7328 14.2285 23.319C12.8788 23.9052 11.664 24.7626 10.6595 25.8379Z"
            fill="white"
        />
        <path
            d="M18.506 30.8909C15.692 30.8928 12.9611 29.9365 10.7629 28.1796C8.56468 26.4226 7.03016 23.9696 6.41187 21.2242C5.79358 18.4789 6.12836 15.6049 7.36113 13.0752C8.5939 10.5455 10.6512 8.51089 13.1944 7.30622C15.7376 6.10154 18.6151 5.79861 21.3534 6.44727C24.0917 7.09593 26.5276 8.65754 28.2601 10.8751C29.9927 13.0926 30.9186 15.8339 30.8856 18.6478C30.8525 21.4617 29.8624 24.1805 28.0783 26.3567C26.9171 27.773 25.4564 28.9143 23.8012 29.6984C22.146 30.4824 20.3375 30.8897 18.506 30.8909ZM18.506 7.76547C16.3825 7.76547 14.3066 8.39518 12.541 9.57495C10.7753 10.7547 9.39915 12.4316 8.58651 14.3935C7.77387 16.3554 7.56124 18.5142 7.97552 20.5969C8.38981 22.6797 9.41239 24.5928 10.914 26.0944C12.4155 27.5959 14.3286 28.6185 16.4114 29.0328C18.4941 29.4471 20.6529 29.2345 22.6148 28.4218C24.5767 27.6092 26.2536 26.233 27.4334 24.4673C28.6131 22.7017 29.2428 20.6258 29.2428 18.5023C29.2428 15.6547 28.1117 12.9238 26.0981 10.9102C24.0846 8.89667 21.3536 7.76547 18.506 7.76547Z"
            fill="white"
        />
        <path
            d="M9.56885 25.8364C9.56885 25.8364 17.8857 35.1278 26.3513 26.7614L27.4415 25.8364C27.4415 25.8364 20.3717 18.5023 13.1946 22.9044L9.56885 25.8364Z"
            fill="white"
        />
        <path
            d="M18.5004 18.5025C20.7811 18.5025 22.6299 16.6536 22.6299 14.373C22.6299 12.0923 20.7811 10.2434 18.5004 10.2434C16.2197 10.2434 14.3708 12.0923 14.3708 14.373C14.3708 16.6536 16.2197 18.5025 18.5004 18.5025Z"
            fill="white"
        />
    </svg>
</template>
