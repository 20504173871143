<template>
    <div id="contact_us" class="contact-us-wrapper">
        <div v-if="showSuccesSection" class="contact-us-content">
            <div class="contact-us-title thank-contact-us-title">
                <div class="title">Let's Talk</div>
                <div class="sub_title">
                    Whether you've got questions, ideas, or are looking to
                    collaborate, our team is on standby to offer you the support
                    you need.
                </div>
            </div>
            <div class="thank-section">
                <CheckIcon />
                <div class="thank-section-title">
                    Thank you for<br class='hidden' /> your message!
                </div>
                <div class="thank-section-sub-title">
                    Our team is currently reviewing your inquiry and will be in touch with you shortly.
                </div>
            </div>
        </div>
        <div v-else class="contact-us-content">
            <div class="contact-us-title">
                <div class="title">Let's Talk</div>
                <div class="sub_title">
                    Whether you've got questions, ideas, or are looking to
                    collaborate, our team is on standby to offer you the support
                    you need.
                </div>
            </div>

            <div class="contact-form">
                <form ref="form" @submit.prevent="sendEmail">
                    <div class="required-fields">
                        <input v-model="name" type="text" name="name" id="input-name" placeholder="Your Name"
                            autocomplete="off" class="input" />
                        <span v-if="!name" for="input-name" class="input-name"></span>
                        <input v-model="email" type="email" name="email" id="input-email" placeholder="Your E-mail"
                            autocomplete="off" class="input" />
                        <span v-if="!email" for="input-email" class="input-email"></span>
                    </div>
                    <textarea v-model="message" placeholder="Message" name="message" class="textarea"></textarea>
                    <BaseButton class="button" :type="typeButton" :disabled="!isButtonDisabled">Send Message
                    </BaseButton>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import emailjs from '@emailjs/browser'
import CheckIcon from './icons/CheckIcon.vue'
import BaseButton from './BaseButton.vue'

export default {
    name: 'ContactUs',
    components: { CheckIcon, BaseButton },
    data() {
        return {
            name: '',
            email: '',
            message: '',
            typeButton: 'disabled',
            showSuccesSection: false,
        }
    },
    computed: {
        isButtonDisabled() {
            return !!this.name && !!this.email
        },
    },
    methods: {
        sendEmail(e) {
            if (this.isButtonDisabled) {
                this.typeButton = 'submit'
                emailjs
                    .sendForm(
                        'service_pkij0f4',
                        'template_attnuoq',
                        e.target,
                        'jxVdNXFZP_4T5qh0_',
                        {
                            name: this.name,
                            email: this.email,
                            message: this.message,
                        }
                    )
                    .then(() => {
                        this.showSuccesSection = true
                    })
            }
        },
    },
}
</script>

<style scoped lang="scss">
.contact-us-wrapper {
    width: 100%;
}

.contact-us-content {
    margin: 70px 0 30px;
    padding: 50px 30px;
    height: 496px;

    border-radius: 30px;
    background-color: #111b21;
}

.title {
    margin-bottom: 35px;

    font-size: 32px;
    font-weight: 600;
    line-height: 45px;
    text-align: center;
    color: #fff;
}

.sub_title {
    display: none;
}

.contact-form {
    display: flex;
    flex-direction: column;

    gap: 20px;
}

.textarea {
    width: 100%;
    max-width: 100%;
    height: 60px;
    resize: none;

    border: none;
    border-bottom: 1px solid #8a8a8a;
    background-color: transparent;

    outline: none;
    color: #fff;

    &::placeholder {
        opacity: 0.7;
        font-size: 16px;
        color: #ffffff;
    }

    &::-webkit-scrollbar {
        width: 3px;
    }

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
        opacity: 0.7;
        border-radius: 20px;
        background-color: #ffffff;
    }
}

.textarea:focus {
    &::placeholder {
        opacity: 0.7;
        font-size: 16px;
        color: #ffffff;
    }
}

.required-fields {
    position: relative;

    display: flex;
    flex-direction: column;
}

.input {
    margin-bottom: 40px;
    padding-bottom: 15px;

    border: none;
    border-bottom: 1px solid #8a8a8a;
    background-color: transparent;

    outline: none;
    color: #fff;

    &::placeholder {
        opacity: 0.7;
        font-size: 16px;
        color: #ffffff;
    }
}

input+.input-name::after {
    position: absolute;
    top: -4px;
    left: 82px;

    content: '*';

    height: 10px;
    width: 10px;

    color: #ff3131;
    opacity: 0.7;
}

input:focus+.input-name::after {
    opacity: 0.4;
}

input+.input-email::after {
    position: absolute;
    left: 86px;
    bottom: 70px;

    content: '*';

    height: 10px;
    width: 10px;

    color: #ff3131;
    opacity: 0.7;
}

input:focus+.input-email::after {
    opacity: 0.4;
}

input:focus::placeholder {
    opacity: 0.4;
}

.button {
    width: 100%;
    margin-top: 50px;

    font-weight: 600;
}

.thank-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 100%;
    width: 100%;

    color: #fff;
}

.thank-section-title {
    margin-top: 40px;

    font-size: 30px;
    font-weight: 600;
    line-height: 42px;
    text-align: center;
}

.thank-section-sub-title {
    margin-top: 15px;

    font-size: 16px;
    line-height: 26px;
    text-align: center;
    color: #fff;
}

.thank-contact-us-title {
    display: none;
}

@media screen and (min-width: 1200px) {
    .contact-us-wrapper {
        box-sizing: content-box;

        display: flex;
        justify-content: center;

        width: 100%;
        padding: 0 40px;
        background-color: #111b21;
    }

    .contact-us-content {
        display: flex;
        justify-content: space-between;

        width: 100%;
        height: 100%;
        max-width: 1300px;
        margin: 0 -40px 0;
        padding: 78px 0;

        border-radius: 0;
    }

    .contact-us-title {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        width: 100%;
        max-width: 530px;
        padding: 82px 0;
    }

    .title {
        margin-bottom: 20px;

        font-size: 54px;
        font-weight: 600;
        line-height: 75.6px;
        color: #fff;
    }

    .sub_title {
        display: block;

        font-size: 16px;
        line-height: 26px;
        color: #ffffff;
    }

    .contact-form {
        width: 100%;
        max-width: 530px;
        padding: 82px 70px;

        border-radius: 20px;
        background-color: #1b252b;
    }

    .thank-section {
        width: 100%;
        height: 500px;
        max-width: 530px;
        padding: 82px 70px;

        border-radius: 20px;
        background-color: #1b252b;
    }

    .hidden {
        display: none;
    }

    .button {
        height: 70px;
    }
}
</style>
