<template>
    <header class="header">
        <div class="logo-icon">
            <LogoIcon />
        </div>

        <div class="header-items">
            <div class="nav-items">
                <a v-for="(item, index) in items" :key="`burger${item + index}`" class="nav-item"
                    :href="`#${item.link}`">
                    {{ item.title }}
                </a>
            </div>
            <a href='#contact_us' class="button">Contact Us</a>
        </div>
        <BurgerMenu :nav-items="items" class="burger-menu" />
    </header>
</template>

<script>
import LogoIcon from './icons/LogoIcon.vue'
import BurgerMenu from './BurgerMenu.vue'

export default {
    name: 'Header',
    components: { LogoIcon, BurgerMenu },
    data() {
        return {
            items: [
                { title: 'About us', link: 'about_us' },
                { title: 'AI ChatBot App', link: 'ai_chatbot' },
                { title: 'Our Values', link: 'values' },
            ],
        }
    },
}
</script>

<style scoped lang="scss">
.header {
    display: flex;
    align-items: center;
    justify-content: center;
}

.header-items {
    display: none;
    justify-content: space-between;

    gap: 130px;
    max-width: 100%;

    .nav-items {
        display: flex;
        align-items: center;

        gap: 56px;
    }

    .button {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 144px;
        height: 50px;

        border: none;
        border-radius: 10px;
        background: #25d366;

        &:hover {
            background: #27B95E;
        }
    }
}

.logo-icon {
    flex: 1;
    justify-self: flex-start;
}

@media screen and (min-width: 1200px) {
    .header {
        justify-content: space-between;

        width: 1300px;
        max-width: 100%;
    }

    .header-items {
        display: flex;
        flex: 2;
    }

    .burger-menu {
        display: none;
    }
}
</style>
