<template>
    <div id="values" class="our-values-wrapper">
        <div class="title">Our Values</div>

        <div class="values">
            <div v-for="(item, index) in values" :key="item.icon + index">
                <component :is="item.icon" />
                <div class="value-title">{{ item.title }}</div>
                <div class="value-text">{{ item.text }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import CreativityIcon from './icons/CreativityIcon.vue'
import TechnicalSkillsIcon from './icons/TechnicalSkillsIcon.vue'
import CollaborationIcon from './icons/CollaborationIcon.vue'
import AdaptabilityIcon from './icons/AdaptabilityIcon.vue'
import ProblemSolvingIcon from './icons/ProblemSolvingIcon.vue'
import PassionTechnologyIcon from './icons/PassionTechnologyIcon.vue'

export default {
    name: 'OurValues',
    components: {
        CreativityIcon,
        TechnicalSkillsIcon,
        CollaborationIcon,
        AdaptabilityIcon,
        ProblemSolvingIcon,
        PassionTechnologyIcon,
    },
    data() {
        return {
            values: [
                {
                    icon: 'CreativityIcon',
                    title: 'Creativity',
                    text: 'We pride ourselves on our innovative spirit, crafting applications that captivate and stand out in a crowded market.',
                },
                {
                    icon: 'TechnicalSkillsIcon',
                    title: 'Technical Expertise',
                    text: 'Our core lies in robust technical skills spanning software and mobile app development to user interface design.',
                },
                {
                    icon: 'CollaborationIcon',
                    title: 'Collaboration',
                    text: 'Unity is our strength, with every team member bringing a commitment to teamwork, idea sharing, and collective problem-solving.',
                },
                {
                    icon: 'AdaptabilityIcon',
                    title: 'Adaptability',
                    text: 'Staying ahead means staying adaptable. We constantly evolve with the latest technologies to craft cutting-edge applications.',
                },
                {
                    icon: 'ProblemSolvingIcon',
                    title: 'Problem Solving',
                    text: "At Faliane, we accept challenges and always strive to optimize our app's performance to enhance user experience.",
                },
                {
                    icon: 'PassionTechnologyIcon',
                    title: 'Passion for Technology',
                    text: 'Our drive is fueled by a relentless passion for technology and app development, keeping us inspired and forward-moving.',
                },
            ],
        }
    },
}
</script>

<style scoped lang="scss">
.our-values-wrapper {
    margin-top: 28px;
}

.title {
    margin-bottom: 40px;

    text-align: center;
    font-size: 36px;
    font-weight: 600;
    line-height: 50px;
}

.values {
    display: grid;

    gap: 50px;
}

.value-title {
    margin: 15px 0 8px;

    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
}

.value-text {
    font-size: 16px;
    line-height: 26px;
}

@media screen and (min-width: 1200px) {
    .our-values-wrapper {
        margin-top: 120px;
        padding-bottom: 120px;
    }

    .title {
        margin-bottom: 70px;

        font-size: 50px;
        font-weight: 600;
        line-height: 70px;
    }

    .values {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;

        row-gap: 100px;
        column-gap: 185px;
        width: 100%;
        max-width: 1300px;
    }

    .value-title {
        margin: 20px 0 8px;

        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
    }
}
</style>
