<template>
    <div class="burger-menu-wrapper">
        <BurgerMenuIcon class="icon" @click="openMenu" />

        <Transition name="fade" mode="out-in">
            <section v-if="showMenu" class="list-section">
                <LogoIcon mode="light" class="logo" />

                <div class="list-items">
                    <div v-for="(item, index) in navItems" :key="`burger${item + index}`" class="list-item"
                        @click="scrollToSection(item.link)">
                        {{ item.title }}
                    </div>

                    <a href='#contact_us' class="button">Contact Us</a>
                </div>
            </section>
        </Transition>
    </div>
</template>

<script>
import LogoIcon from './icons/LogoIcon.vue'
import BurgerMenuIcon from './icons/BurgerMenuIcon.vue'

export default {
    components: { LogoIcon, BurgerMenuIcon },
    props: {
        navItems: { type: Array, default: () => [] },
    },
    data() {
        return {
            link: '',
            showMenu: false,
        }
    },
    created() {
        document.addEventListener('click', this.close)
    },
    unmounted() {
        document.removeEventListener('click', this.close)
    },
    methods: {
        scrollToSection(id) {
            this.showMenu = false
            this.togglePageScroll(false)
            const yOffset = document.getElementById(id).offsetTop
            window.scrollTo({ top: yOffset, behavior: 'smooth' })
        },
        close({ target }) {
            const list = document.querySelector('.burger-menu-wrapper')

            if (!list.contains(target)) {
                this.showMenu = false
                this.togglePageScroll(this.showMenu)
            }
        },
        togglePageScroll(isOpen) {
            if (isOpen) {
                document.body.classList.add('overflow-hidden')
            } else {
                document.body.classList.remove('overflow-hidden')
            }
        },
        openMenu() {
            this.showMenu = true
            this.togglePageScroll(this.showMenu)
        },
    },
}
</script>

<style scoped lang="scss">
.icon {
    position: absolute;
    top: 26px;
    right: 20px;

    cursor: pointer;
}

.list-section {
    overflow: hidden;

    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 330px;
    height: 100dvh;

    background: #111b21f2;

    .logo {
        width: 124px;
        height: 40px;
        margin-bottom: 70px;
    }

    .list-items {
        display: flex;
        flex-direction: column;
        align-items: center;

        gap: 50px;
        width: 100%;
        padding: 0 30px;

        cursor: pointer;

        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        color: #ffffff;
    }

    .button {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 50px;

        border: none;
        border-radius: 10px;
        background: #25d366;

        color: #111b21;

        &:hover {
            background: #27B95E;
        }
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
