<template>
    <svg
        width="80"
        height="58"
        viewBox="0 0 80 58"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_15_7146)">
            <path
                d="M78.8284 1.15667C77.2664 -0.385557 74.7339 -0.385557 73.1716 1.15667L25.2491 48.4674L6.82851 30.2821C5.26648 28.7399 2.73398 28.74 1.17164 30.2821C-0.390546 31.8242 -0.390546 34.3243 1.17164 35.8666L22.4207 56.8438C23.9822 58.3859 26.5166 58.3848 28.0775 56.8438L78.8284 6.74128C80.3906 5.1992 80.3905 2.6989 78.8284 1.15667Z"
                fill="#25D366"
            />
        </g>
        <defs>
            <clipPath id="clip0_15_7146">
                <rect width="80" height="58" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>
