<template>
    <svg
        width="60"
        height="60"
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect width="60" height="60" rx="10" fill="#111B21" />
        <path
            d="M26.0864 21.3261C26.1803 22.6795 27.7267 23.4206 28.8444 22.6517C29.8277 21.9749 31.1698 22.6799 31.1698 23.8745V28.7893H34.8518C34.1979 26.1192 36.315 23.5178 39.1312 23.7123C41.609 23.884 43.4465 26.2699 42.8277 28.7893H46.5469C49.6589 24.0841 46.5248 17.2766 40.4604 16.9652C41.1461 13.344 38.354 10.0061 34.6948 10.0061C33.373 10.0061 32.1519 10.4455 31.1698 11.1856V18.521C31.1698 19.7148 29.8287 20.4209 28.8443 19.7438C27.6483 18.9209 25.9827 19.8299 26.0864 21.3261Z"
            fill="white"
        />
        <path
            d="M18.4789 28.7894C19.6729 28.7894 20.3789 30.1308 19.7015 31.1151C18.8825 32.3051 19.7817 33.9758 21.2843 33.872C22.6408 33.778 23.3783 32.2304 22.6103 31.1148C21.9332 30.131 22.6388 28.7894 23.8331 28.7894H28.8269V25.1843C26.3018 25.8046 23.9206 23.9601 23.7491 21.4881C23.5547 18.6838 26.1451 16.5526 28.8269 17.2093V11.1857C24.6227 8.01726 18.5999 11.6351 19.5225 16.8876C13.4219 17.2118 10.3347 24.0341 13.4736 28.7894H18.4789ZM46.5077 31.1324H41.5179C40.3234 31.1324 39.6181 29.7906 40.2952 28.8069C41.0637 27.6908 40.3243 26.1437 38.9692 26.0498C37.4668 25.9455 36.5671 27.6162 37.3864 28.8067C38.0636 29.7909 37.3581 31.1324 36.1638 31.1324H31.17V34.7355C33.8441 34.0807 36.4427 36.2031 36.2478 39.0142C36.0762 41.4872 33.6944 43.3301 31.17 42.7105V48.8144C35.3742 51.9828 41.397 48.365 40.4744 43.1124C46.4283 42.7959 49.7378 36.1163 46.5077 31.1324Z"
            fill="white"
        />
        <path
            d="M33.9104 38.8522C34.0141 37.3567 32.3486 36.4469 31.1526 37.2701C30.1687 37.9466 28.8269 37.2421 28.8269 36.0473V31.1326H25.143C25.7807 33.7279 23.8039 36.2199 21.1509 36.2199C18.4728 36.2199 16.5388 33.6964 17.167 31.1326H13.4741C10.3368 35.8873 13.418 42.7208 19.5365 43.035C18.8508 46.6562 21.6429 49.9941 25.302 49.9941C26.6238 49.9941 27.8449 49.5547 28.827 48.8145V41.4008C28.827 40.2073 30.1683 39.5009 31.1523 40.178C32.2678 40.9458 33.8163 40.2084 33.9104 38.8522Z"
            fill="white"
        />
    </svg>
</template>
