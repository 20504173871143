<template>
    <svg
        width="97"
        height="32"
        viewBox="0 0 97 32"
        fill="red"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect width="31.2754" height="31.2754" rx="3.90943" :fill="modeType.logoBg" />
        <path
            d="M13.7151 7.03697V24.269H10.1646V7.03697H13.7151ZM20.5795 14.3985V17.1679H12.7446V14.3985H20.5795ZM21.408 7.03697V9.81824H12.7446V7.03697H21.408Z"
            :fill="modeType.logo"
        />
        <path
            d="M44.8306 21.9424V15.3384C44.8306 14.7309 44.7043 14.2062 44.4518 13.7643C44.1994 13.3225 43.8285 12.9832 43.3393 12.7465C42.8501 12.5098 42.2465 12.3914 41.5285 12.3914C40.8658 12.3914 40.274 12.5098 39.7533 12.7465C39.2404 12.9753 38.8341 13.287 38.5342 13.6815C38.2423 14.0681 38.0963 14.4981 38.0963 14.9715L36.6761 14.9597C36.6761 14.4784 36.7945 14.0129 37.0312 13.5631C37.2679 13.1134 37.6032 12.711 38.0372 12.3559C38.4711 12.0009 38.9879 11.7208 39.5876 11.5156C40.1951 11.3026 40.8618 11.1961 41.5877 11.1961C42.503 11.1961 43.3078 11.3499 44.0021 11.6577C44.7043 11.9654 45.2527 12.4269 45.6472 13.0424C46.0417 13.6578 46.2389 14.431 46.2389 15.3621V21.5519C46.2389 21.9937 46.2705 22.4513 46.3336 22.9247C46.4046 23.3982 46.5033 23.7887 46.6295 24.0964V24.2385H45.1264C45.0318 23.9544 44.9568 23.5994 44.9016 23.1733C44.8542 22.7393 44.8306 22.329 44.8306 21.9424ZM45.1619 16.7823L45.1856 17.8711H42.4044C41.6785 17.8711 41.0275 17.9382 40.4515 18.0723C39.8835 18.1986 39.4022 18.3879 39.0077 18.6404C38.6131 18.885 38.3094 19.1809 38.0963 19.528C37.8912 19.8752 37.7886 20.2697 37.7886 20.7116C37.7886 21.1692 37.903 21.5874 38.1319 21.9661C38.3686 22.3448 38.6999 22.6486 39.126 22.8774C39.56 23.0983 40.0728 23.2088 40.6646 23.2088C41.4536 23.2088 42.1479 23.0628 42.7476 22.7709C43.3551 22.479 43.8561 22.0963 44.2506 21.6229C44.6451 21.1495 44.9095 20.6327 45.0436 20.0725L45.659 20.8891C45.5565 21.2836 45.3671 21.6899 45.0909 22.1081C44.8227 22.5184 44.4716 22.905 44.0376 23.268C43.6036 23.623 43.0908 23.915 42.499 24.1438C41.9152 24.3647 41.2524 24.4752 40.5107 24.4752C39.6744 24.4752 38.9445 24.3174 38.3212 24.0017C37.7058 23.6861 37.2245 23.2561 36.8773 22.7117C36.538 22.1594 36.3684 21.5361 36.3684 20.8418C36.3684 20.2105 36.5025 19.6464 36.7708 19.1493C37.0391 18.6444 37.4257 18.2183 37.9307 17.8711C38.4435 17.5161 39.0589 17.2478 39.7769 17.0663C40.5028 16.877 41.3155 16.7823 42.215 16.7823H45.1619Z"
            :fill="modeType.text"
        />
        <path
            d="M51.6121 6.05961V24.2385H50.2037V6.05961H51.6121Z"
            :fill="modeType.text"
        />
        <path
            d="M57.0563 11.4328V24.2385H55.6479V11.4328H57.0563ZM55.3994 7.72837C55.3994 7.46011 55.4822 7.23524 55.6479 7.05377C55.8215 6.8644 56.0582 6.76972 56.358 6.76972C56.6579 6.76972 56.8946 6.8644 57.0681 7.05377C57.2496 7.23524 57.3403 7.46011 57.3403 7.72837C57.3403 7.98875 57.2496 8.21362 57.0681 8.40298C56.8946 8.58445 56.6579 8.67519 56.358 8.67519C56.0582 8.67519 55.8215 8.58445 55.6479 8.40298C55.4822 8.21362 55.3994 7.98875 55.3994 7.72837Z"
            :fill="modeType.text"
        />
        <path
            d="M68.714 21.9424V15.3384C68.714 14.7309 68.5877 14.2062 68.3352 13.7643C68.0827 13.3225 67.7119 12.9832 67.2227 12.7465C66.7335 12.5098 66.1299 12.3914 65.4119 12.3914C64.7492 12.3914 64.1574 12.5098 63.6367 12.7465C63.1238 12.9753 62.7175 13.287 62.4176 13.6815C62.1257 14.0681 61.9797 14.4981 61.9797 14.9715L60.5595 14.9597C60.5595 14.4784 60.6779 14.0129 60.9146 13.5631C61.1513 13.1134 61.4866 12.711 61.9206 12.3559C62.3545 12.0009 62.8713 11.7208 63.471 11.5156C64.0785 11.3026 64.7452 11.1961 65.4711 11.1961C66.3864 11.1961 67.1912 11.3499 67.8855 11.6577C68.5877 11.9654 69.1361 12.4269 69.5306 13.0424C69.9251 13.6578 70.1223 14.431 70.1223 15.3621V21.5519C70.1223 21.9937 70.1539 22.4513 70.217 22.9247C70.288 23.3982 70.3867 23.7887 70.5129 24.0964V24.2385H69.0098C68.9152 23.9544 68.8402 23.5994 68.785 23.1733C68.7376 22.7393 68.714 22.329 68.714 21.9424ZM69.0453 16.7823L69.069 17.8711H66.2877C65.5619 17.8711 64.9109 17.9382 64.3349 18.0723C63.7669 18.1986 63.2856 18.3879 62.891 18.6404C62.4965 18.885 62.1928 19.1809 61.9797 19.528C61.7746 19.8752 61.672 20.2697 61.672 20.7116C61.672 21.1692 61.7864 21.5874 62.0152 21.9661C62.2519 22.3448 62.5833 22.6486 63.0094 22.8774C63.4434 23.0983 63.9562 23.2088 64.548 23.2088C65.337 23.2088 66.0313 23.0628 66.631 22.7709C67.2385 22.479 67.7395 22.0963 68.134 21.6229C68.5285 21.1495 68.7929 20.6327 68.927 20.0725L69.5424 20.8891C69.4398 21.2836 69.2505 21.6899 68.9743 22.1081C68.7061 22.5184 68.355 22.905 67.921 23.268C67.487 23.623 66.9742 23.915 66.3824 24.1438C65.7986 24.3647 65.1358 24.4752 64.3941 24.4752C63.5578 24.4752 62.8279 24.3174 62.2046 24.0017C61.5892 23.6861 61.1079 23.2561 60.7607 22.7117C60.4214 22.1594 60.2518 21.5361 60.2518 20.8418C60.2518 20.2105 60.3859 19.6464 60.6542 19.1493C60.9225 18.6444 61.3091 18.2183 61.814 17.8711C62.3269 17.5161 62.9423 17.2478 63.6603 17.0663C64.3862 16.877 65.1989 16.7823 66.0984 16.7823H69.0453Z"
            :fill="modeType.text"
        />
        <path
            d="M75.3298 14.1667V24.2385H73.9214V11.4328H75.2706L75.3298 14.1667ZM75.0103 17.0427L74.383 16.6876C74.4304 15.9302 74.5842 15.22 74.8446 14.5573C75.105 13.8945 75.4561 13.3106 75.8979 12.8057C76.3476 12.3007 76.8723 11.9062 77.472 11.6221C78.0795 11.3381 78.7502 11.1961 79.484 11.1961C80.1231 11.1961 80.6951 11.2829 81.2001 11.4565C81.705 11.63 82.1351 11.9062 82.4901 12.2849C82.8452 12.6636 83.1134 13.1489 83.2949 13.7406C83.4843 14.3324 83.5789 15.0544 83.5789 15.9065V24.2385H82.1587V15.8946C82.1587 15.011 82.0364 14.3206 81.7918 13.8235C81.5472 13.3185 81.2001 12.9595 80.7503 12.7465C80.3006 12.5335 79.768 12.4269 79.1526 12.4269C78.474 12.4269 77.8783 12.5769 77.3655 12.8767C76.8605 13.1686 76.4344 13.5513 76.0873 14.0247C75.748 14.4902 75.4876 14.9912 75.3062 15.5278C75.1247 16.0564 75.0261 16.5614 75.0103 17.0427Z"
            :fill="modeType.text"
        />
        <path
            d="M92.195 24.4752C91.3586 24.4752 90.5893 24.3213 89.8871 24.0136C89.1928 23.7059 88.5852 23.2719 88.0645 22.7117C87.5516 22.1515 87.1532 21.4887 86.8691 20.7234C86.593 19.9502 86.4549 19.1059 86.4549 18.1907V17.6818C86.4549 16.6955 86.6009 15.8039 86.8928 15.007C87.1847 14.2101 87.5871 13.5276 88.1 12.9595C88.6128 12.3914 89.1967 11.9575 89.8516 11.6577C90.5144 11.3499 91.2087 11.1961 91.9346 11.1961C92.7473 11.1961 93.4692 11.342 94.1004 11.634C94.7316 11.918 95.2603 12.3244 95.6863 12.853C96.1203 13.3738 96.4477 13.9931 96.6687 14.7111C96.8896 15.4212 97 16.2063 97 17.0663V17.9303H87.307V16.7231H95.5917V16.5574C95.5759 15.8394 95.4299 15.1688 95.1538 14.5454C94.8855 13.9142 94.4831 13.4014 93.9466 13.0069C93.41 12.6124 92.7394 12.4151 91.9346 12.4151C91.3349 12.4151 90.7826 12.5413 90.2777 12.7938C89.7806 13.0463 89.3506 13.4093 88.9876 13.8827C88.6326 14.3482 88.3564 14.9044 88.1592 15.5514C87.9698 16.1905 87.8751 16.9006 87.8751 17.6818V18.1907C87.8751 18.9008 87.9777 19.5636 88.1828 20.179C88.3959 20.7865 88.6957 21.3231 89.0823 21.7886C89.4768 22.2541 89.9423 22.617 90.4789 22.8774C91.0154 23.1378 91.6071 23.268 92.2541 23.268C93.0116 23.268 93.6822 23.1299 94.2661 22.8537C94.85 22.5697 95.3747 22.1239 95.8402 21.5164L96.7278 22.2028C96.4517 22.6131 96.1045 22.9918 95.6863 23.339C95.2761 23.6861 94.7829 23.9623 94.2069 24.1674C93.631 24.3726 92.9603 24.4752 92.195 24.4752Z"
            :fill="modeType.text"
        />
    </svg>
</template>

<script>
export default {
    props: {
        mode: {type: String, default: 'dark'},
    },
    computed: {
        modeType() {
            if (this.mode === 'light') {
                return {
                    text: '#FFFFFF',
                    logo: '#071117',
                    logoBg: '#FFFFFF',
                }
            }

            return {
                text: '#111B21',
                logo: '#FCF5EB',
                logoBg: '#111B21',
            }
        },
    },
}
</script>
