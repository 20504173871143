<template>
    <button :class="['base-button', disabled && 'disabled']">
        <slot></slot>
    </button>
</template>

<script>
export default {
    name: 'BaseButton',
    props: {
        disabled: { type: Boolean, default: false },
    },
}
</script>

<style scoped lang="scss">
.base-button {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 50px;

    border: none;
    border-radius: 10px;
    background: #25d366;

    line-height: 22px;
    font-size: 18px;
    color: #111b21;
    cursor: pointer;

    &:hover {
        background: #27B95E;
    }
}

.disabled {
    opacity: 0.6;
    cursor: not-allowed;
}
</style>
