<template>
    <svg
        width="54"
        height="42"
        viewBox="0 0 54 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M17.6018 13.123C19.8373 13.123 21.6555 11.306 21.6555 9.07222C21.6555 6.8384 19.8373 5.02148 17.6018 5.02148C15.3664 5.02148 13.5471 6.8384 13.5471 9.07222C13.5471 11.306 15.3664 13.123 17.6018 13.123ZM17.6018 6.66751C18.9287 6.66751 20.0073 7.74634 20.0073 9.07119C20.0073 10.396 18.9276 11.4749 17.6018 11.4749C16.276 11.4749 15.1954 10.396 15.1954 9.07119C15.1954 7.74634 16.276 6.66751 17.6018 6.66751Z"
            fill="white"
        />
        <path
            d="M50.0756 26.3529H46.7791V5.97059C46.7791 2.67853 44.0986 0 40.8042 0H6.60286C3.30841 0 0.62793 2.67853 0.62793 5.97059V29.0294C0.62793 32.3215 3.30841 35 6.60286 35H24.1156V38.7059C24.1156 40.5228 25.5949 42 27.4121 42H50.0756C51.8928 42 53.3721 40.5228 53.3721 38.7059V29.6471C53.3721 27.8301 51.8928 26.3529 50.0756 26.3529ZM6.60286 1.64706H40.8042C43.19 1.64706 45.1308 3.58647 45.1308 5.97059V25.8279L33.7156 12.8162C33.4062 12.4636 33.0251 12.181 32.5977 11.9873C32.1702 11.7936 31.7064 11.6933 31.2371 11.6931H31.235C30.2852 11.6941 29.3807 12.1038 28.7554 12.8193L17.8058 25.3328L12.7292 19.5465C12.4198 19.1939 12.0387 18.9113 11.6112 18.7176C11.1838 18.5239 10.72 18.4236 10.2507 18.4234H10.2486C9.29879 18.4244 8.39431 18.8341 7.769 19.5496L2.27619 25.8269V5.97059C2.27619 3.58647 4.21701 1.64706 6.60286 1.64706ZM2.28134 29.226L2.30297 29.2291C2.39053 28.5199 2.69752 27.8476 3.18994 27.2856L9.00931 20.6346C9.32248 20.2774 9.77369 20.0715 10.2496 20.0715H10.2507C10.7256 20.0715 11.1768 20.2763 11.4899 20.6325L16.7108 26.5846L10.7884 33.3529H6.60286C4.28294 33.3529 2.38435 31.5185 2.28134 29.226ZM24.1156 29.6471V33.3529H12.9775L29.9957 13.9032C30.3089 13.546 30.7601 13.3401 31.236 13.3401H31.2371C31.712 13.3401 32.1632 13.545 32.4764 13.9012L43.3991 26.3529H27.4121C25.5949 26.3529 24.1156 27.8301 24.1156 29.6471ZM51.7239 38.7059C51.7239 39.6138 50.9842 40.3529 50.0756 40.3529H27.4121C26.5035 40.3529 25.7638 39.6138 25.7638 38.7059V29.6471C25.7638 28.7391 26.5035 28 27.4121 28H50.0756C50.9842 28 51.7239 28.7391 51.7239 29.6471V38.7059Z"
            fill="white"
        />
        <path
            d="M42.8645 29.2354C42.4092 29.2354 42.0404 29.6039 42.0404 30.0589V38.2942C42.0404 38.7492 42.4092 39.1177 42.8645 39.1177C43.3198 39.1177 43.6886 38.7492 43.6886 38.2942V30.0589C43.6886 29.6039 43.3198 29.2354 42.8645 29.2354ZM37.8847 29.8221C37.7796 29.4742 37.4593 29.2354 37.0956 29.2354C36.732 29.2354 36.4106 29.4742 36.3065 29.8221L33.8341 38.0574C33.7755 38.2653 33.8009 38.4878 33.905 38.6771C34.009 38.8665 34.1833 39.0073 34.3903 39.0694C34.5973 39.1316 34.8204 39.1099 35.0116 39.0092C35.2028 38.9085 35.3467 38.7367 35.4123 38.5309L35.8543 37.0589H38.3359L38.7779 38.5309C38.809 38.6345 38.8602 38.7309 38.9286 38.8147C38.997 38.8985 39.0812 38.968 39.1765 39.0192C39.2718 39.0704 39.3762 39.1024 39.4839 39.1133C39.5915 39.1242 39.7003 39.1138 39.8039 39.0827C40.0132 39.0199 40.1889 38.8766 40.2924 38.6843C40.396 38.492 40.4189 38.2665 40.3561 38.0574L37.8847 29.8221ZM36.3487 35.4118L37.0956 32.9248L37.8425 35.4118H36.3487Z"
            fill="white"
        />
    </svg>
</template>
