<template>
    <div class="our-features-wrapper">
        <div class="our-features-content">
            <div class="messages-content-desktop-top">
                <div class="messages-top">
                    <MessageItem
                        :is-person="false"
                        message="Hello!"
                        class="message-1"
                    />
                    <MessageItem
                        message="A good day"
                        :message-left="true"
                        class="message-2"
                    />
                </div>
                <div class="messages-top">
                    <MessageItem message="Hello!" class="message-3" />
                    <MessageItem
                        :is-person="false"
                        message="How can I help you today?"
                        :message-left="true"
                        class="message-4"
                    />
                </div>
                <div class="messages-top">
                    <MessageItem
                        :message-left="true"
                        message="Generate a cat image"
                        class="message-5"
                    />
                </div>
            </div>
            <div class="messages-content">
                <MessageItem :is-person="false" message="Hello!" />
                <MessageItem
                    message="Hello!"
                    :message-left="true"
                    class="item"
                />
                <MessageItem
                    :is-person="false"
                    message="How can I help you today?"
                />
            </div>

            <div class="title">Our Features</div>

            <div class="features">
                <div
                    v-for="item in features"
                    :key="item.icon"
                    class="feature-item"
                >
                    <component :is="item.icon" />
                    <div class="feature-text" v-html="item.text"></div>
                </div>
            </div>

            <div class="messages-content">
                <MessageItem
                    message="How are you?"
                    :message-left="true"
                    class="item"
                />
                <MessageItem :is-person="false" message="I'm doing well" />
                <MessageItem
                    message="Generate a cat image"
                    :message-left="true"
                    class="item"
                />
            </div>

            <div class="messages-content-desktop-bottom">
                <MessageItem message="What is the weather today" />
                <MessageItem message="How are you?" class="message-7" />
                <MessageItem
                    :is-person="false"
                    message="I'm doing well"
                    class="message-8"
                />
                <div class="message-bottom">
                    <MessageItem
                        message="Write a Python script"
                        class="message-9"
                    />
                    <MessageItem
                        :is-person="false"
                        :message-left="true"
                        message="That's wonderful to"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MessageItem from './MessageItem.vue'
import QuickIcon from './icons/QuickIcon.vue'
import ConversationIcon from './icons/ConversationIcon.vue'
import GenerateImgIcon from './icons/GenerateImgIcon.vue'
import TranslateIcon from './icons/TranslateIcon.vue'

export default {
    name: 'OurFeatures',
    components: {
        MessageItem,
        QuickIcon,
        ConversationIcon,
        GenerateImgIcon,
        TranslateIcon,
    },
    data() {
        return {
            features: [
                {
                    icon: 'QuickIcon',
                    text: `Quick solutions<br/>for any task`,
                },
                {
                    icon: 'ConversationIcon',
                    text: `Natural human-like<br/>conversations`,
                },
                {
                    icon: 'GenerateImgIcon',
                    text: `Works with<br/>texts and images`,
                },
                {
                    icon: 'TranslateIcon',
                    text: `Writes, summarizes,<br/>and translates`,
                },
            ],
        }
    },
}
</script>

<style scoped lang="scss">
.our-features-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;

    margin-top: 70px;
    width: 100%;

    border-radius: 30px;
    background-color: #111b21;
}

.our-features-content {
    display: flex;
    flex-direction: column;

    width: 100%;
    padding: 30px 20px 32px;
}

.messages-content {
    display: flex;
    flex-direction: column;
}

.messages-content-desktop-top,
.messages-content-desktop-bottom {
    display: none;
}

.title {
    display: none;
}

.item {
    align-self: flex-end;

    margin: 15px 0;
}

.features {
    display: flex;
    flex-direction: column;

    gap: 10px;
    margin-top: 50px;
}

.feature-item {
    display: flex;
    flex-direction: column;
    align-items: center;

    height: 254px;
    padding: 50px 20px 0;

    border-radius: 20px;
    background-color: #1b252b;

    color: #ffffff;
    text-align: center;
}

.feature-text {
    margin-top: 30px;

    font-size: 16px;
    line-height: 25px;
}

@media screen and (min-width: 1200px) {
    .our-features-wrapper {
        min-width: 100%;
        margin-top: 120px;
    }

    .our-features-content {
        width: 100%;
        max-width: 1300px;
        padding: 68px 0 92px;
    }

    .title {
        display: block;

        margin-bottom: 50px;

        font-size: 38px;
        font-weight: 600;
        line-height: 52px;
        text-align: center;
        color: #ffffff;
    }

    .messages-content {
        display: none;
    }

    .messages-content-desktop-top {
        display: grid;
        grid-template-columns: 1fr 2fr 0.8fr;

        height: 200px;
    }

    .messages-content-desktop-bottom {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 2fr;

        height: 210px;

        .message-7 {
            margin-top: 45%;
        }

        .message-8 {
            margin-top: 18%;
            margin-left: 15%;
        }

        .message-9 {
            margin-top: 25%;
            margin-left: 15%;
        }
    }

    .messages-top {
        display: flex;
        justify-content: space-between;

        .message-1 {
            margin-top: 2%;
        }

        .message-2 {
            margin-top: 25%;
        }

        .message-3 {
            margin-left: 15%;
        }

        .message-4 {
            margin-top: 8%;
        }

        .message-5 {
            margin-top: 45%;
            margin-left: auto;
        }
    }

    .message-bottom {
        display: flex;
        justify-content: space-between;
    }

    .features {
        flex-direction: row;

        margin-bottom: 58px;
    }

    .feature-item {
        flex: 1;
    }

    .feature-item {
        padding: 45px 20px 0;
    }

    .feature-text {
        margin-top: 26px;

        font-size: 16px;
        line-height: 25px;
    }
}

@media screen and (min-width: 1200px) {
    .our-features-content {
        padding-left: 20px;
        padding-right: 20px;
    }
}
</style>
