<template>
    <svg
        width="25"
        height="19"
        viewBox="0 0 25 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_43_823)">
            <path
                d="M23.9583 10.5962H1.04167C0.466667 10.5962 0 10.1052 0 9.50009C0 8.89501 0.466667 8.40394 1.04167 8.40394H23.9583C24.5333 8.40394 25 8.89501 25 9.50009C25 10.1052 24.5333 10.5962 23.9583 10.5962ZM23.9583 2.19231H1.04167C0.466667 2.19231 0 1.70123 0 1.09615C0 0.491077 0.466667 0 1.04167 0H23.9583C24.5333 0 25 0.491077 25 1.09615C25 1.70123 24.5333 2.19231 23.9583 2.19231ZM23.9583 18.9999H8.04167C7.46667 18.9999 7 18.5088 7 17.9038C7 17.2987 7.46667 16.8076 8.04167 16.8076H23.9583C24.5333 16.8076 25 17.2987 25 17.9038C25 18.5088 24.5333 18.9999 23.9583 18.9999Z"
                fill="#111B21"
            />
        </g>
        <defs>
            <clipPath id="clip0_43_823">
                <rect width="25" height="19" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>
