<template>
    <svg
        width="60"
        height="60"
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect width="60" height="60" rx="10" fill="#111B21" />
        <path
            d="M41.4062 43.3333C40.0974 43.3333 38.9747 44.1455 38.5101 45.2941H35.7812V47.647H38.5101C38.9747 48.7955 40.0974 49.6078 41.4062 49.6078C43.1294 49.6078 44.5312 48.2004 44.5312 46.4705C44.5312 44.7406 43.1294 43.3333 41.4062 43.3333ZM46.875 36.6666C46.3788 36.6662 45.8897 36.7853 45.4487 37.0138L42.5471 34.5863C42.3365 34.4101 42.071 34.3137 41.7969 34.3137H35.7812V36.6666H41.3726L43.9167 38.7951C43.8063 39.1199 43.75 39.4607 43.75 39.8039C43.75 41.5337 45.1519 42.9411 46.875 42.9411C48.5981 42.9411 50 41.5337 50 39.8039C50 38.074 48.5981 36.6666 46.875 36.6666ZM46.875 26.8627C45.5662 26.8627 44.4434 27.6749 43.9788 28.8235H35.7812V31.1764H43.9788C44.4434 32.325 45.5662 33.1372 46.875 33.1372C48.5981 33.1372 50 31.7298 50 29.9999C50 28.2701 48.5981 26.8627 46.875 26.8627ZM42.5471 25.4135L45.4487 22.986C45.8897 23.2145 46.3788 23.3336 46.875 23.3333C48.5981 23.3333 50 21.9259 50 20.196C50 18.4661 48.5981 17.0588 46.875 17.0588C45.1519 17.0588 43.75 18.4661 43.75 20.196C43.75 20.549 43.8092 20.888 43.9167 21.2048L41.3726 23.3333H35.7812V25.6862H41.7969C42.071 25.6862 42.3365 25.5897 42.5471 25.4135ZM41.4062 16.6666C43.1294 16.6666 44.5312 15.2592 44.5312 13.5293C44.5312 11.7995 43.1294 10.3921 41.4062 10.3921C40.0974 10.3921 38.9747 11.2043 38.5101 12.3529H35.7812V14.7058H38.5101C38.9747 15.8544 40.0974 16.6666 41.4062 16.6666ZM21.7188 29.9999C21.7188 34.5409 25.3987 38.2352 29.9219 38.2352C31.1793 38.2352 32.3713 37.9493 33.4375 37.4395V22.5603C32.3713 22.0505 31.1793 21.7646 29.9219 21.7646C25.3987 21.7646 21.7188 25.459 21.7188 29.9999Z"
            fill="white"
        />
        <path
            d="M32.2656 10H27.5781C26.9309 10 26.4062 10.5267 26.4062 11.1765V12.7078C24.2022 13.1597 22.105 14.0319 20.2284 15.2772L19.1495 14.1941C18.6919 13.7347 17.9499 13.7347 17.4923 14.1941L14.1777 17.5216C13.7201 17.981 13.7201 18.726 14.1777 19.1854L15.2566 20.2685C14.0161 22.1525 13.1473 24.2579 12.6973 26.4706H11.1719C10.5247 26.4706 10 26.9973 10 27.6471V32.3529C10 33.0027 10.5247 33.5294 11.1719 33.5294H12.6973C13.1473 35.7421 14.0161 37.8475 15.2566 39.7315L14.1777 40.8147C13.9579 41.0353 13.8345 41.3345 13.8345 41.6465C13.8345 41.9585 13.9579 42.2577 14.1777 42.4783L17.4923 45.8059C17.9499 46.2653 18.6919 46.2653 19.1495 45.8059L20.2284 44.7228C22.1051 45.9681 24.2022 46.8403 26.4062 47.2922V48.8235C26.4062 49.4733 26.9309 50 27.5781 50H32.2656C32.9128 50 33.4375 49.4733 33.4375 48.8235V39.9826C32.3373 40.3743 31.1541 40.5882 29.9219 40.5882C24.1063 40.5882 19.375 35.8384 19.375 30C19.375 24.1616 24.1063 19.4118 29.9219 19.4118C31.1541 19.4118 32.3373 19.6257 33.4375 20.0174V11.1765C33.4375 10.5267 32.9128 10 32.2656 10Z"
            fill="white"
        />
    </svg>
</template>
