<template>
    <div class="splash-wrapper">
        <img class="bg-img-mobile" src="../assets/images/bg-mobile.webp" />
        <img class="bg-img-desktop" src="../assets/images/bg-desktop.webp" />
        <div class="splash-heading">
            <div>
                <div class="title">FALIANE:<br /> The Future is Now</div>
                <div class="sub-title">
                    <span class="bold"> Join the AI revolution.</span> Make
                    smarter choices with the apps that understand your needs.
                </div>
            </div>

            <img class="messages-desktop" src="../assets/images/messages-desktop.webp" />
        </div>

        <div id="about_us" class="facts">
            <div class="quote">
                In today's fast-paced world, technology serves as the backbone
                of innovation and convenience. At Faliane, we're not just
                developing apps; we're crafting experiences that enhance your
                daily life.
            </div>

            <div class="company-info">
                <div v-for="(item, index) in сompanyInfo" :key="item.value + index" class="company-info-item">
                    <div class="item-value">{{ item.value }}</div>
                    <div class="item-title" v-html="item.title"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SplashSection',
    data() {
        return {
            сompanyInfo: [
                {
                    value: '20m+',
                    title: `Installs Worldwide`,
                },
                {
                    value: '27+',
                    title: 'Our team',
                },
                {
                    value: '1m+',
                    title: `Monthly Active Users`,
                },
            ],
        }
    },
}
</script>

<style scoped lang="scss">
.splash-wrapper {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.bg-img-mobile {
    width: 100%;
    max-width: 350px;
    margin-top: 20px;
}

.title {
    margin: 25px 0 25px;

    font-size: 26px;
    font-weight: 600;
    line-height: 36px;
    color: #111b21;
}

.sub-title {
    margin-bottom: 70px;

    font-size: 17px;
    line-height: 24px;
    color: #111b21;
}

.quote {
    position: relative;

    padding-left: 15px;
    margin-bottom: 30px;

    font-size: 17px;
    font-weight: 400;
    line-height: 27px;
    color: #111b21;

    &::before {
        position: absolute;
        top: 4px;
        left: 0;

        content: ' ';

        width: 4px;
        height: 100%;
        background-color: #25d366;
    }
}

.company-info {
    display: flex;
    justify-content: space-between;

    gap: 10px;
    margin-bottom: 70px;
}

.company-info-item {
    position: relative;

    display: flex;
    flex-direction: column;

    height: 110px;
    width: 100%;
    max-width: 110px;
    padding-top: 24px;

    border-radius: 20px;
    background-color: #111b21;

    color: #ffffff;
    text-align: center;

    &::after {
        position: absolute;
        bottom: 2px;
        left: 50%;
        transform: translate(-50%, 50%);

        content: '';

        height: 4px;
        width: 57px;

        border-radius: 9px 9px 0 0;
        background-color: #25d366;
    }
}

.item-value {
    font-size: 22px;
    font-weight: 600;
    line-height: 30px;
}

.item-title {
    padding: 0 5px;

    font-size: 12px;
    line-height: 16px;
}

.bg-img-desktop {
    display: none;
}

.messages-desktop {
    display: none;
}

.bold {
    font-weight: 700;
}

@media screen and (min-width: 1200px) {
    .bg-img-mobile {
        display: none;
    }

    .hidden {
        display: none;
    }

    .bg-img-desktop {
        display: block;

        width: 100%;
        max-width: 1840px;
        margin: 28px 0 120px;
    }

    .separator {
        display: block;
    }

    .messages-desktop {
        display: block;

        width: 80%;
        max-width: 451px;
    }

    .splash-heading {
        position: absolute;
        top: 10%;

        display: flex;
        justify-content: space-between;

        width: 100%;
        max-width: 1300px;
        padding: 0 40px;
    }

    .title {
        font-size: 40px;
        line-height: 45px;
        color: #ffffff;
    }

    .sub-title {
        width: 50%;
        margin-top: 50px;

        font-size: 22px;
        line-height: 30px;
        color: #ffffff;
    }

    .facts {
        display: flex;
        justify-content: space-between;
        align-items: center;

        width: 100%;
        max-width: 1300px;

        .quote {
            display: block;

            width: 100%;
            max-width: 562px;
            padding-left: 0;

            font-size: 24px;
            line-height: 38px;

            &::before {
                display: none;
            }
        }

        .company-info {
            width: 100%;
            gap: 20px;
            margin-bottom: 0;
            justify-content: flex-end;
        }

        .company-info-item {
            display: flex;
            align-items: center;

            min-width: 200px;
            height: 200px;
            padding-top: 50px;

            border-radius: 20px;

            &::after {
                height: 5px;
                width: 96px;
            }

            .item-value {
                font-size: 36px;
                line-height: 50px;
            }

            .item-title {
                margin-top: 5px;

                font-size: 16px;
                line-height: 22px;
            }
        }
    }
}

@media screen and (min-width: 1600px) {
    .messages-desktop {
        max-width: 451px;
    }

    .splash-heading {
        top: 18%;

        padding: 0;
    }

    .title {
        font-size: 54px;
        line-height: 75px;
    }

    .sub-title {
        width: 100%;
        margin-top: 110px;
    }
}
</style>
