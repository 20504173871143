<template>
    <div class="landing-page">
        <div class="landing-wrapper">
            <Header />
            <SplashSection />
            <OurProduct />
            <OurFeatures />
            <OurValues />
            <ContactUs />
            <Footer />
        </div>
    </div>
</template>

<script>
import Header from './Header.vue'
import SplashSection from './SplashSection.vue'
import OurProduct from './OurProduct.vue'
import OurFeatures from './OurFeatures.vue'
import OurValues from './OurValues.vue'
import ContactUs from './ContactUs.vue'
import Footer from './Footer.vue'

export default {
    name: 'LandingPage',
    components: {
        Header,
        SplashSection,
        OurProduct,
        OurFeatures,
        OurValues,
        ContactUs,
        Footer,
    },
}
</script>

<style scoped lang="scss">
.landing-page {
    display: flex;
    flex-direction: column;
    align-items: center;

    height: 100%;

    background: #fcf5eb;
}

.landing-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;
    padding: 20px;
    max-width: 390px;
}

@media screen and (min-width: 1200px) {
    .landing-wrapper {
        padding: 20px 40px;
        width: 100%;
        max-width: 100%;
    }
}
</style>
