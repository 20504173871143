<template>
    <div class="message-item">
        <PersonIcon v-if="isPerson" class="icon" />
        <img v-else class="icon" src="../assets/images/ai.png" />

        <div
            :class="[
                'message',
                isPerson && 'message-person',
                messageLeft && 'message-left',
            ]"
        >
            <div>{{ message }}</div>
        </div>
    </div>
</template>

<script>
import PersonIcon from './icons/PersonIcon.vue'

export default {
    name: 'MessageItem',
    components: {PersonIcon},
    props: {
        isPerson: {type: Boolean, default: true},
        messageLeft: {type: Boolean, default: false},
        message: {type: String, default: ''},
    },
}
</script>

<style scoped lang="scss">
.message-item {
    position: relative;

    width: 37px;
    height: 37px;
}

.icon {
    width: 37px;
    height: 37px;
}

.message {
    position: absolute;
    top: 22px;
    left: 32px;

    width: max-content;
    padding: 7px 12px;

    border-radius: 0 12px 12px 12px;
    background-color: #ffffff;

    font-size: 13px;
    line-height: 18px;
}

.message-person {
    background-color: #cceebb;
}

.message-left {
    right: 32px;
    left: inherit;

    transform: scaleX(-1);

    div {
        transform: scaleX(-1);
    }
}

@media screen and (min-width: 1200px) {
    .message-item {
        width: 58px;
        height: 58px;
    }

    .icon {
        width: 58px;
        height: 58px;
    }

    .message {
        top: 34px;
        left: 50px;

        padding: 8px 15px;
    }

    .message-left {
        right: 32px;
        left: inherit;
    }

    .message-left {
        right: 52px;
    }

    .message {
        font-size: 16px;
        line-height: 22px;
    }
}
</style>
