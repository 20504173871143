<template>
    <footer class="footer">
        <div class="footer-container">
            <div class="nav-items">
                <LogoIcon mode="light" class="logo" />

                <a
                    v-for="(item, index) in items"
                    :key="`burger${item + index}`"
                    class="nav-item"
                    :href="`#${item.link}`"
                >
                    {{ item.title }}
                </a>
            </div>

            <div class="company-info">
                <div class="company-info-text">
                    Faliane Ltd<br />Imathias 13, 3117 Limassol, Cyprus<br />©2024
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import LogoIcon from './icons/LogoIcon.vue'

export default {
    name: 'FooterSection',
    components: {LogoIcon},
    data() {
        return {
            items: [
                {title: 'About us', link: 'about_us'},
                {title: 'AI ChatBot App', link: 'ai_chatbot'},
                {title: 'Our Values', link: 'values'},
            ],
        }
    },
}
</script>

<style scoped lang="scss">
.footer {
    box-sizing: content-box;

    width: 100%;
    padding: 0 20px;
    margin: 0 -20px -20px;

    background-color: #071117;
}

.footer-container {
    padding: 50px 20px;
}

.nav-items {
    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 50px;
    width: 100%;
    padding: 0 30px;

    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: #ffffff;
}

.nav-item {
    cursor: pointer;
}

.logo {
    width: 124px;
    height: 40px;
    margin-bottom: 10px;
}

.company-info {
    display: flex;
    flex-direction: column;

    gap: 20px;
    margin-top: 60px;

    text-align: center;

    color: #ffffff;
}

.company-info-text {
    opacity: 0.7;
    font-size: 14px;
    line-height: 20px;
}

.hr {
    width: 1px;
    height: 15px;
    margin: 0 15px;

    opacity: 0.7;
    background-color: #ffffff;
}

@media screen and (min-width: 1200px) {
    .footer {
        display: flex;
        justify-content: center;

        padding: 0 40px;
        margin: 0 -40px -20px;
    }

    .footer-container {
        width: 100%;
        max-width: 1300px;
        padding: 32px 0;
    }

    .nav-items {
        flex-direction: row;
    }

    .logo {
        margin-right: 20%;
    }

    .nav-items {
        padding: 0;
    }

    .nav-item {
        margin-right: 5%;
    }

    .company-info {
        flex-direction: row;
    }

    .company-info {
        gap: 25%;
        margin-top: 18px;
    }

    .company-info-text {
        text-align: justify;
    }
}
</style>
