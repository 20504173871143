<template>
    <Landing />
</template>

<script>
import Landing from './components/Landing.vue'

export default {
    name: 'App',
    components: {
        Landing,
    },
}
</script>

<style>
* {
    margin: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;

    font-weight: 400;
    font-family: 'Fira Sans', sans-serif;
}

a {
    text-decoration: none;
    cursor: pointer;
    color: inherit;
}

.overflow-hidden {
    overflow: hidden;
}
</style>
